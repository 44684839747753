import create from "zustand";

const useStore = create((set) => ({
  locale: {},
  setLocale: (loc) =>
    set(() => ({
      locale: loc,
    })),
}));

export default useStore;
